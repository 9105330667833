<template>
  <a-layout id="components-layout-demo-side" style="width: 100%;height: 100%">
    <a-layout-sider v-model="collapsed" collapsible v-if="isPc">
      <div style="display: flex;justify-content: center;align-items: center"
           @click="$router.push('/personality/home')">
        <div style="width: 100%;height: 52px;margin-top: 6px">
          <img src="@/assets/logo.png" alt="" height="100%"/>
        </div>
      </div>
      <a-menu theme="dark" mode="inline"
              style="height: 92%">


        <a-menu-item key="9" @click="$router.push('/personality/home')">
          <a-icon type="home"/>
          <span>个人首页</span>
        </a-menu-item>

        <a-menu-item key="1" @click="$router.push('/getsteamid')">
          <a-icon type="heat-map"/>
          <span>在线作图</span>
        </a-menu-item>
        <a-sub-menu key="sub1">
          <span slot="title"><a-icon type="tool"/><span>工具列表</span></span>
          <a-menu-item key="5" @click="$router.push('/personality/blackList')">
            <a-icon type="user-delete"/>
            <span>黑名单查询</span>
          </a-menu-item>
          <a-menu-item key="2" @click="$router.push('/personality/vacFinder')">
            <a-icon type="close-circle"/>
            <span>封禁查询</span>
          </a-menu-item>

          <a-menu-item key="3" @click="initWhere(1)">
            <a-icon type="radar-chart"/>
            <span>战绩查询</span>
          </a-menu-item>

          <a-menu-item key="10" @click="$router.push('/personality/imgCompress')">
            <a-icon type="file-image"/>
            <span>图片压缩</span>
          </a-menu-item>

          <a-menu-item key="4" @click="$router.push('/personality/ChangePwd')">
            <a-icon type="form"/>
            <span>修改密码</span>
          </a-menu-item>
        </a-sub-menu>

        <a-menu-item key="6" @click="$router.push('/personality/UseRecord')">
          <a-icon type="ordered-list"/>
          <span>作图记录</span>
        </a-menu-item>

        <a-menu-item key="7" @click="$router.push('/personality/Payment')">
          <a-icon type="crown"/>
          <span>会员充值</span>
        </a-menu-item>

        <a-menu-item key="8" @click="$router.push('/personality/Question')">
          <a-icon type="pull-request"/>
          <span>意见反馈</span>
        </a-menu-item>
      </a-menu>


    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">


        <a-dropdown v-model="headerPopmentPhone" v-if="!isPc">
          <div style="float: right;margin-right: 20px;letter-spacing: 1px;font-size: 20px"><a class="log"
                                                                                              style="font-size: 20px">
            菜单
          </a>
          </div>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="$router.push('/personality/home')">
              个人主页
            </a-menu-item>
            <a-menu-item key="2" @click="$router.push('/getsteamId')">
              在线作图
            </a-menu-item>
            <a-menu-item key="3" @click="$router.push('/personality/payment')">
              会员充值
            </a-menu-item>
            <a-menu-item key="4" @click="$router.push('/personality/vacFinder')">
              封禁查询
            </a-menu-item>
            <a-menu-item key="5" @click="$router.push('/personality/blackList')">
              查黑名单
            </a-menu-item>
            <a-menu-item key="6" @click="initWhere(1)">
              战绩查询
            </a-menu-item>
          </a-menu>
          <a-menu-item key="7" @click="$router.push('/personality/imgCompress')">
            图片压缩
          </a-menu-item>
        </a-dropdown>


        <a-dropdown v-model="headerPopment">
          <div style="float: right;margin-right: 30px;letter-spacing: 1px;font-size: 15px"> 你好! <a class="log">{{
              $store.getters.Mail
            }}</a>
          </div>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="$router.push('/')">
              返回首页
            </a-menu-item>
            <a-menu-item key="2" disabled>
              <hr>
            </a-menu-item>
            <a-menu-item key="3" @click="logOut">
              退出登录
            </a-menu-item>
          </a-menu>
        </a-dropdown>


      </a-layout-header>
      <a-layout-content
          :style="{ margin: '24px 16px',  background: '#fff', minHeight: '280px',overflow:'auto',borderRadius:'15px',boxShadow:'1px 1px 15px 3px rgba(0,0,0,0.1)' }"
      >
        <router-view></router-view>
      </a-layout-content>
      <div style="font-size: 19px;text-align: center;color: rgba(0,0,0,0.5)">Powered by Yskam</div>
    </a-layout>
  </a-layout>
</template>
<script>

export default {
  name: 'Main',
  data() {
    return {
      collapsed: false,
      headerPopment: false,
      isPc: true,
      headerPopmentPhone: false
    };
  },
  methods: {
    initWhere(v) {
      const a = document.createElement('a')
      switch (v) {
        case 1:
          a.href = '/index.html'
          break;
      }
      a.click()
    },
    logOut() {
      this.$store.commit("setF5Token", "");
      this.$store.commit("setUsrRole", "");
      this.$store.commit("setAcToken", "");
      this.$store.commit("setIsLogin", "0");
      this.$store.commit("setMail", "");
      this.$router.replace('/');
    },
  },
  created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.7'
    this.isPc = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
    if (localStorage.getItem('AcToken') == '') {
      this.$message.error('请先登录')
      this.$router.replace('/')
      return
    }
  }

};
</script>
<style>
.log {
  transition: all .3s;
  font-size: 15px
}

.log:hover {
  font-size: 16px;
  color: red;
}

</style>